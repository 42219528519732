<template>
  <span>
    <Alert />
    <b-card no-body class="h-100">
      <b-card-header>
        <b-form-row>
          <b-col>
            <h3>{{ $route.params.user || $t("views.user.NewUser") }}</h3>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <back-button />
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-form-row>
      </b-card-header>
      <b-card-body class="overflow-auto">
        <b-form @submit="onSubmit" @reset="onReset" id="UserFormData">
          <h5>{{ $t("views.user.GeneralData") }}</h5>

          <b-form-group
            label-cols="12"
            content-cols="12"
            label-cols-sm="2"
            label-align="left"
            label-align-sm="right"
            content-cols-sm="9"
            :label="$t('views.user.User')"
            label-for="UserUserName"
          >
            <b-form-input
              id="UserUserName"
              v-model="clonUser.userName"
              required
              :placeholder="$t('views.user.EnterUser')"
              :disabled="!isNew"
              :state="this.isValid === null ? null : Boolean(clonUser.userName)"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="this.isValid === null ? null : Boolean(clonUser.userName)"
            >
              {{ $t("views.user.Required") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols="12"
            content-cols="12"
            label-cols-sm="2"
            label-align="left"
            label-align-sm="right"
            content-cols-sm="9"
            :label="$t('views.user.Name')"
            label-for="UserName"
          >
            <b-form-input
              id="UserName"
              v-model="clonUser.name"
              required
              :placeholder="$t('views.user.EnterName')"
              :state="this.isValid === null ? null : Boolean(clonUser.name)"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="this.isValid === null ? null : Boolean(clonUser.name)"
            >
              {{ $t("views.user.Required") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols="12"
            content-cols="12"
            label-cols-sm="2"
            label-align="left"
            label-align-sm="right"
            content-cols-sm="9"
            :label="$t('views.user.Email')"
            label-for="UserEmail"
          >
            <b-form-input
              id="UserEmail"
              type="email"
              v-model="clonUser.mail"
              required
              :placeholder="$t('views.user.EnterEmail')"
              :state="this.isValid"
            ></b-form-input>
            <b-form-invalid-feedback :state="this.isValid">
              {{ $t("views.user.RequiredEmail") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="Customers.length > 1"
            label-cols="12"
            content-cols="12"
            label-cols-sm="2"
            label-align="left"
            label-align-sm="right"
            content-cols-sm="9"
            :label="$t('views.user.Customer')"
            label-for="UserCustomer"
          >
            <b-form-select
              id="UserCustomer"
              v-model="clonUser.idCustomer"
              :options="Customers"
              value-field="idCustomer"
              text-field="nameCustomer"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </b-card-body>
      <b-card-footer>
        <b-button-toolbar>
          <b-button-group class="ml-auto">
            <b-button
              id="UserSaveUser"
              variant="primary"
              v-b-tooltip.hover
              size="sm"
              :title="$t('views.user.SaveUser')"
              @click="onSubmit"
              >{{ $t("views.user.SaveUser") }}</b-button
            >
          </b-button-group>
        </b-button-toolbar>
      </b-card-footer>
    </b-card>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Alert from "@/components/general/alert";

export default {
  components: {
    Alert,
  },
  watch: {
    UnitOrganitaion(newValue) {
      if (!newValue) return;
      if (!this.isNew) {
        this.loadUser();
        return;
      }
      this.fetchCustomers();
    },
  },
  data() {
    return {
      show: true,
      clonUser: {
        idCustomer: null,
        mail: null,
        name: null,
        userName: null,
      },
      isValid: null,
    };
  },
  computed: {
    ...mapGetters("OrganizationalUnit", ["UnitOrganitaion"]),
    ...mapGetters("User", ["user", "Customers"]),
    isNew() {
      return this.$route.params.user === undefined;
    },
  },
  created() {
    this.show = true;
    if (!this.isNew) {
      this.loadUser();
      return;
    }
    this.fetchCustomers();
  },
  methods: {
    ...mapActions("User", [
      "getUser",
      "updateUser",
      "createUser",
      "getCustomers",
    ]),
    fetchCustomers() {
      const that = this;
      this.getCustomers({
        api: this.$api,
        bEnable: true,
      }).then((data) => {
        if (data.length)
          that.clonUser.idCustomer =
            that.clonUser.idCustomer || data[0].idCustomer;
      });
    },
    loadUser() {
      this.getUser({
        api: this.$api,
        userName: this.$route.params.user,
      })
        .then((data) => {
          this.$bvToast.toast(this.$t("views.user.UserDataLoaded"), {
            id: "UserUserLoaded",
            variant: "success",
            title: this.$t("views.user.UserLoading"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          Object.assign(this.clonUser, data);
          this.fetchCustomers();
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.user.UserLoading"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.isNew) {
        this.newUser();
        return;
      }
      this.oldUser();
    },
    onReset(evt) {
      evt.preventDefault();
      Object.assign(this.clonUser, this.user);
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    newUser() {
      if (
        !this.validateEmail(this.clonUser.mail) ||
        !this.clonUser.name ||
        !this.clonUser.userName
      ) {
        this.isValid = false;
        return;
      }
      this.createUser({
        api: this.$api,
        user: {
          idCustomer: this.clonUser.idCustomer,
          UserData: {
            mail: this.clonUser.mail,
            name: this.clonUser.name,
            userName: this.clonUser.userName,
          },
          Roles_OU: [
            {
              IdOU: this.clonUser.idCustomer,
              IdRole: 4,
            },
          ],
        },
      })
        .then((response) => {
          this.$bvToast.toast(response, {
            variant: "success",
            title: this.$t("views.user.UserDataLoaded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$router.push({
            name: "Roles",
            params: { user: this.clonUser.userName },
          });
          this.isValid = null;
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.user.UserDataLoaded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.clonUser.mail = "";
          this.clonUser.name = "";
          this.clonUser.userName = "";
          this.isValid = null;
        });
    },
    oldUser() {
      if (
        !this.validateEmail(this.clonUser.mail) ||
        !this.clonUser.name ||
        !this.clonUser.userName
      ) {
        this.isValid = false;
        return;
      }
      this.updateUser({
        api: this.$api,
        user: this.clonUser,
      })
        .then((message) => {
          this.$bvToast.toast(message, {
            variant: "success",
            title: this.$t("views.user.UserDataLoaded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$router.push({
            name: "Users",
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.user.UserDataLoaded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
  },
};
</script>

<style></style>
